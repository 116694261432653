@import '../../styles/variables.scss';

.register-success {
  width: 450px;
  color: $primary-color-text;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  line-height: 28px;
  height: 90%;
  background-color: $secondary-background;
  border-radius: 20px;
  box-shadow: $primary-color-box-shadow-background;
  padding: 40px;

  .register-success-top {
    font-size: 36px;
    color: $success-color;
  }

  .register-success-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .register-success {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
}

@media only screen and (max-width: 415px) {
  .register-success {
    padding: 20px;

    .register-success-text {
      font-size: 14px;
    }
  }
}