@import '../../styles/variables.scss';

.page-parser-container {
  width: 100%;
  overflow: auto;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;

  .page-parser {
    gap: 30px;
    height: 100%;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-nagivation {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.no-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: $primary-color-text;
}