@import '../../styles/variables.scss';

.stats-content {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  .stats-content-area {
    height: 100%;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .stats-content-title {
      margin-bottom: 20px;
    }

  }
}