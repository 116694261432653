@import '../../styles/variables.scss';

.settings-content {
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $primary-color-text;

  .settings-content-area {
    height: 100%;
    margin: 20px 0;
    border-radius: 20px;
    background-color: $secondary-background;
    padding: 20px;
  }
}