@import '../../styles/variables.scss';

.MuiTableContainer-root {
  background-color: $transparent;

  .MuiTableHead-root {

    .MuiTableCell-root {
      color: $primary-color-text;
      background-color: $secondary-background;
    }

  }

  .MuiTableCell-root {
    color: $primary-color-text;

    .primary-btn {
      min-width: 100px;
    }
  }

  .styled-table-collapsible-row {
    color: $primary-color-text;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}