@import '../../styles/stylingVariables.scss';

.sidebar-subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  transition: all 0.5s;

  .subscription-card {
    display: flex;
    width: 180px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: $transparent;

    svg {
      width: 100px;
      height: 100px;
      margin-top: -50px;
      color: $warning-color;
    }

    .subscription-text {
      color: $neutrals-30;
    }
  }

}