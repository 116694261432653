@import '../../styles/variables.scss';

.styled-menu {
  width: 100%;
  height: 50px;
  position: relative;

  .menu-button {
    background-color: $transparent;
    color: $primary-color-text;
    font-size: 17px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 100%;

    &.active {
      color: $tertiary-color;
    }

    &:hover {
      color: $tertiary-color;

    }
  }

  .styled-menu-items {
    position: absolute;
    z-index: 2;
    top: 57px;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: $secondary-background;
    box-shadow: $primary-color-box-shadow-background;

    .styled-menu-item {

      .nav-link {
        padding: 0 20px;
        height: 40px;

        .nav-link-name {
          font-size: 16px;
          padding: 0;
        }
      }
    }
  }
}