@import '../../styles/stylingVariables.scss';

.navbar-container {
  width: 100%;
  background-color: $neutrals-100;
  box-shadow: $primary-box-shadow;
  display: flex;
  height: 65px;

  .navbar {
    width: 100%;
    margin: 0 auto;
    color: $neutrals-30;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .navbar-logo {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: left;

      .logo {
        display: flex;
        gap: 10px;
        align-items: center;
        color: $neutrals-30;
        background-color: $transparent;

        svg {
          width: 25px;
          height: 25px;
        }

        .logo-text {
          font-size: 22px;
          margin-top: -3px;
          font-weight: 500;
          font-family: 'Open Sans';
        }

        &:active,
        &:hover {
          color: $neutrals-30;
          background-color: $transparent;
        }
      }
    }

    .logo-text-accent {
      font-size: 28px;
      font-family: 'Open Sans';
      font-weight: 400;
    }

    .navbar-buttons {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      margin-right: 25px;
    }

    .navbar-burger {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar-container {

    .navbar {
      width: 90%;

      .navbar-burger {
        display: block;

        button {

          display: flex;
          justify-content: center;
        }

        svg {
          height: 40px;
          width: 40px;
        }
      }

      .navbar-buttons {
        height: 100px;
        flex-direction: column;
        width: 100%;
        background-color: $neutrals-100;
        box-shadow: $primary-box-shadow;
        border-bottom: 1px solid $neutrals-50;

        .nav-link {
          position: relative;
          box-shadow: none;

          .nav-link-border {
            display: none;
          }
        }
      }

      .navbar-buttons.extended-links {
        position: absolute;
        top: 70px;
        opacity: 1;
        right: 0;
        z-index: 2;
        padding: 20px;
        height: auto;
        transition: all 200ms;
        gap: 25px;
        align-items: center;

        .nav-link {
          font-size: 20px;
        }
      }

      .navbar-buttons.unextended-links {
        z-index: -2;
        left: 0;
        position: absolute;
        height: 0px;
        opacity: 0;
        top: 30px;
        transition: opacity 100ms ease;
      }
    }


  }
}