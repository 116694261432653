@import '../../styles/stylingVariables.scss';

.styled-rating {
  max-width: 350px;
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .styled-rating-label {
    width: 100%;
    color: $neutrals-30;
    font-size: 14px;
    text-align: left;
  }

  .MuiRating-root {
    color: $primary-100;
    font-size: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .MuiRating-iconEmpty {
    color: $primary-100;
  }
}