@import '../../styles/stylingVariables.scss';

.image-viewer {
  img {
    max-width: 150px;
    max-height: 150px;
  }

  .no-image {
    color: $neutrals-50;

    svg {
      width: 50px;
      height: 50px;

    }
  }
}