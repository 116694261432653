@import '../../styles/stylingVariables.scss';

.sidebar-container {
  transition: width 0.3s;
  width: 80px;
  background-color: $neutrals-100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0 50px 0;
  box-shadow: $primary-box-shadow;

  .sidebar-expand-btn {
    display: flex;
    align-items: center;
    padding: 0 20px 0 0;
    justify-content: flex-end;

    svg {
      width: 28px;
      height: 28px;
      transition: transform 0.2s;
    }
  }

  .line {
    width: 100%;
    margin: 0 auto;
    border: 1px solid $neutrals-80;
  }

  .sidebar-subscription {
    opacity: 0;
  }

  &.extended {
    width: 250px;
    transition: width 0.2s;

    .sidebar-expand-btn {
      svg {
        transform: rotateZ(180deg);
        transition: transform 0.2s;
      }
    }

    .sidebar-btns {
      width: 100%;
      display: flex;
      flex-direction: column;
    }


    .sidebar-subscription {
      opacity: 1;
    }
  }
}