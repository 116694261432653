@import '../../styles/variables.scss';

.register-container {
  width: 60%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .register {
    width: 450px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    background-color: $secondary-background;
    border-radius: 20px;
    box-shadow: $primary-color-box-shadow-background;
    padding: 20px;

    .register-top {
      color: $primary-color-text;
      font-size: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .register-line {
        width: 50px;
        border-bottom: 3px solid $primary-color;
        border-radius: 25px;
      }
    }

    .register-fields {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .register-text-password {

        svg {
          color: $primary-color-text;
        }
      }

      .pass-details {
        font-size: 12px;
        white-space: pre-line;
        text-align: left;
        color: $primary-color-text;

        .checked {
          color: $success-color;
        }

        .unchecked {
          color: $error-color;
        }
      }

      .register-btn {
        margin-top: 50px;
      }

      .styled-checkbox {
        .MuiFormControlLabel-root {
          .MuiFormControlLabel-label {
            font-size: 12px;
          }


          .MuiFormControlLabel-asterisk {
            display: none;
          }
        }
      }
    }
  }

  .register-create-acc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
    color: $primary-color-text;
  }

}

@media only screen and (max-width: 768px) {
  .register-container {
    width: 100%;

    .register {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      background-color: $secondary-background;
    }

  }
}

@media only screen and (max-width: 415px) {
  .register-container {
    .register {
      box-shadow: none;
      gap: 30px;

      .register-fields {

        .styled-input {
          width: 280px;
        }

        .register-btn {
          margin-top: 30px;
        }

      }
    }

  }
}