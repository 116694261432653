@import '../../styles/variables.scss';

.styled-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $primary-background-var1;
  color: $primary-color-text;
  line-height: 30px;
}