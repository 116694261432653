.MuiAlert-root {
  .MuiAlert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiAlert-message {
    text-align: left;
  }

  .MuiAlert-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}