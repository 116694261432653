@import '../../styles/variables.scss';

.login-container {
  width: 60%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .login {
    width: 450px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    background-color: $secondary-background;
    border-radius: 20px;
    box-shadow: $primary-color-box-shadow-background;
    padding: 20px;

    .login-top {
      color: $primary-color-text;
      font-size: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .login-line {
        width: 50px;
        border-bottom: 3px solid $primary-color;
        border-radius: 25px;
      }
    }

    .login-fields {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .login-text-password {
        svg {
          color: $primary-color-text;
        }
      }

      .login-btn {
        margin-top: 30px;
      }
    }

    .forgot-pass-field {
      margin-top: 10px;
    }

    .login-create-acc {
      color: $primary-color-text;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: 14px;

    }
  }
}

@media only screen and (max-width: 768px) {
  .login-container {
    width: 100%;

    .login {
      background-color: $secondary-background;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
    }

  }
}

@media only screen and (max-width: 415px) {
  .login-container {
    .login {
      box-shadow: none;
      gap: 30px;

      .forgot-pass-field {
        margin-top: 10px;
      }

      .login-fields {
        .styled-input {
          width: 280px;
        }

        .login-btn {
          margin-top: 30px;
        }

      }
    }

  }
}