@import '../../styles/variables.scss';

.home-authorized-container {
  gap: 30px;

  .primary-btn {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .secondary-btn {
    min-width: 75px;
  }

  .home-welcome-section {
    width: 100%;
    background-color: $primary-color-var1;
    background: linear-gradient(30deg, $primary-color-var1 0%, $primary-color 80%);
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .welcome-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .home-companies-section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 5px;

    .companies-cards {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
  }

  .home-locations-section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 5px;

    .locations-cards {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
  }

  .home-stats-section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 5px;

    .stats-cards {
      display: flex;
      flex-direction: row;
      gap: 30px;

    }
  }
}